@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.JobDashboardApplicationsCount {
  .count-col {
    text-align: end;
    @extend .col-3;
  }
  .count-label-col {
    @extend .col-6;
  }
}
