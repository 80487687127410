@import "../../../scss/_colors";
@import "../../../scss/_layout";

.RecruiterFeedback {
  @extend .simple-spaced-rows;
  .feedback-paragraph {
    background-color: $light-background;
    padding: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 0.5em;
  }

  .label-row {
    color: $label-grey;
    padding-top: 1em;
  }

  .action-col {
    text-decoration: underline;
  }
}
