@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";
@import "../../../scss/_forms";

.ApplicationUpdateForm {
  @extend .form-with-rows;
  h1 {
    // Fix alignment problem with edit icon
    margin-bottom: 0.5em;
  }

  .name-row + .contact-row {
    margin-top: 1rem;
  }
  .candidateInformationContact {
    @extend .spaced-children;
  }

  .first-row {
    align-items: flex-start;
  }

  .contact-col {
    // Remove space to the left of links
    .btn {
      text-align: left;
    }

    // Add a small space between edit inputs so that they don't touch
    & > * + * {
      margin-top: 2px;
    }
  }

  .label-row {
    color: $label-grey;
  }

  .icon-col {
    @extend .col-md-auto;
  }

  .download-icon {
    transform: scale(1.2);
  }

  .resume-row {
    margin-bottom: 1rem;
  }
  .col-actionButton {
    @extend .form-actions;
  }

  .effective-col {
    text-align: right;
  }
}
