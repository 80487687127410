@import "../../scss/_bootstrap-configuration";
@import "../../scss/_colors";
@import "../../scss/_layout";
@import "~bootstrap/scss/_grid";

.ProfileCreationForm {
  // Center items horizontally
  text-align: center;
  .row {
    justify-content: center;
  }

  .modal-body-container {
    padding: 3em;
    @extend .spaced-children;
  }

  .FormControl {
    margin-bottom: 1em;
  }

  .form-error {
    color: $error-red;
  }

  .profile-msg-col {
    font-weight: bold;
  }

  .input-col-container {
    @extend .col-12, .col-lg-10;
  }

  .picture {
    height: auto;
    width: 50%;
  }

  .action-row-container {
    justify-content: center;
  }

  .continue-action {
    @extend .col-auto;
  }

  .sign-out-action {
    margin-top: 0.4em;
    @extend .col-12, .col-lg-3;
  }
}
