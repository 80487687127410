@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_buttons";
@import "~bootstrap/scss/_utilities";
@import "~bootstrap/scss/utilities/_api";
@import "./_colors";

//body
body {
  background-color: $maw-grey;
  // Always display the vertical scrollbar (except on mobile devices) so that content does not move to the left
  // while some other content is loading and the page is not tall enough to have a vertical scrollbar
  overflow-y: scroll;
}

// Buttons
.ActionButtonWithIcon {
  &.btn {
    border-width: 2px;
  }

  @include button-variant(
    $background: white,
    $border: $maw-green,
    $color: $maw-blue,
    $hover-background: $maw-green,
    $hover-color: white
  );
}

// Links
.ActionLink,
a {
  text-decoration-thickness: from-font;
}

.clickable-text {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

// Inflate element to all available space
.inflate {
  width: 100%;
  height: 100%;
}

// Widgets
.widget-block {
  @extend .shadow-sm, .p-4, .m-1, .bg-white, .rounded;
}

//champ de donnee a modifier
.data-field {
  color: darken($light-background, 30%);
}

//statut d'une job
.job-status {
  border-radius: 5em;
  background-color: none !important;
  border: solid 1px;
  border-color: $maw-green;
  color: $maw-green;
}
