@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.ApplicationsList {
  @extend .wrap-spaced-children;
  .select-option {
    color: $maw-blue;
  }
  .action-col {
    @extend .col-auto;
  }
}
