@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/grid";
@import "../../../scss/_colors";

.JobSaveForm {
  .current-step-link {
    font-weight: bold;
  }

  .form-row {
    justify-content: center;
  }

  .title-step-container {
    @extend .col-12, .col-lg-6;
  }

  .location-step-container {
    @extend .col-12, .col-lg-7;
  }

  .salary-step-container {
    @extend .col-12, .col-sm-8, .col-md-6, .col-lg-5;
  }

  .WysiwygEditor .WysiwygEditor-editor {
    background-color: $maw-white;
  }

  .current-step-link {
    font-weight: bold;
  }

  .col-actions {
    justify-content: right;
    text-align: right;
  }

  .progress-action {
    margin-top: 1em;
    font-size: 0.8em;
  }

  .progress-span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .step-wizard-list {
    list-style-type: none;
    border-radius: 10px;
    display: flex;
    padding: 1.5em 1.5em;
    position: relative;
    justify-content: center;
  }

  .step-wizard-item {
    padding: 0 1em;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 3em;
    position: relative;
  }

  .step-wizard-item + .step-wizard-item:after {
    content: "";
    position: absolute;
    left: 0;
    top: 1.3em;
    background: $maw-green;
    width: 100%;
    height: 1px;
    transform: translate(-50%);
    z-index: -10;
  }

  .progress-count {
    @extend .cercle-progress;
    position: relative;
    z-index: 10;
    color: $maw-white;
    background-color: $maw-green;
  }

  .progress-count:before {
    content: "";
    height: 0.5em;
    width: 1em;
    border-left: 3px solid $maw-white;
    border-bottom: 3px solid $maw-white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%) rotate(-45deg);
    transform-origin: center center;
  }

  .progress-count-number {
    @extend .cercle-progress;
    position: relative;
    z-index: 10;
    color: $maw-green;
    border: solid 1px $maw-green;
    background-color: $maw-white;
  }

  .progress-label {
    font-size: 0.8em;
    margin-top: 1em;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cercle-progress {
    height: 2.5em;
    width: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    margin: 0 auto;
  }

  @include media-breakpoint-down(md) {
    .step-div {
      font-size: 0.7em;
    }
  }
}
