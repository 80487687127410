@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.StandardPromptWidget {
  .ActionLink.btn {
    text-align: left;
  }

  .prompt-row {
    @extend .g-0;
  }

  .bullet-col {
    @extend .col-auto;
    margin-right: 0.3rem;
  }
}
