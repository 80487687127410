@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.RecruiterProfile {
  h1 {
    // Fix alignment problem with edit icon
    margin-bottom: 0;
  }

  .name-row + .contact-row {
    margin-top: 1rem;
  }

  .first-row {
    align-items: flex-start;
  }

  .col-container {
    @extend .simple-spaced-rows;
  }

  .contact-col {
    // Remove space to the left of links
    .btn {
      text-align: left;
    }

    // Add a small space between edit inputs so that they don't touch
    & > * + * {
      margin-top: 2px;
    }
  }

  .label-row {
    color: $label-grey;
    padding-top: 1em;
  }

  .icon-col {
    @extend .col-auto;
  }

  .download-icon {
    transform: scale(1.5);
  }
}
