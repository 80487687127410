@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.InputTextAreaEditComponent {
  .textarea {
    height: 10rem;
  }

  .input-row {
    align-items: center;

    // Remove gutter, we'll set it manually
    @extend .g-0;
  }

  .button-col {
    // Place buttons at the same place as the edit icon so that edit box does not change size
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    .btn {
      padding: 0;
    }

    // Display buttons on top of each other
    & > * + * {
      display: block;
      margin-top: 0.5rem;
    }
  }

  // Override Bootstrap when it displays the button icon in black instead of white
  .btn-danger {
    color: $maw-white;

    &:hover,
    &:focus,
    &:active {
      color: $maw-white;
    }
  }

  .error {
    color: $danger;
  }

  .button-row {
    text-align: center;
  }
}
