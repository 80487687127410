.Loader {
  .hidden-contents {
    display: none;
  }

  .visible-contents {
    display: inherit;
  }

  .hidden-spinner {
    display: none;
  }

  .visible-spinner {
    display: inherit;
  }
}
