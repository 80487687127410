@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_modal";

.SuccessModal {
  text-align: center;

  .modal-dialog {
    // Center dialog vertically
    @extend .modal-dialog-centered;
  }

  .modal-content {
    border-color: $success-green;
    border-width: medium;

    .modal-body {
      padding: 2rem;

      // Make some space between elements
      & > * + * {
        margin-top: 2rem;
      }

      .status-icon {
        height: 5rem;
      }
    }
  }
}
