@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";
@import "../../../scss/_index";

.ProfileBlock {
  .displayName {
    @extend .col-auto;
  }

  .data {
    @extend .data-field;
  }

  .personalInformation {
    @extend  .formContainer, .blockContainer;
  }

  .formContainer {
    @extend .spaced-children;
  }

  .icon-col {
    @extend .col-auto;
  }

  .blockContainer {
    @extend .widget-block;
  }
  .companyInformation{
    @extend .blockContainer, .formContainer;
  }
}
.deleteUpdateContainer {
  padding-top: 1em;
  @extend .spaced-children;
}
