@import "./_bootstrap-configuration";

// This file holds variables related to the height of the header and that need to be used in various scss files.

// Compute the height of the header links. It is equal to:
// 1 line of text + top/bottom padding around the text + top/bottom border outline width + top/bottom padding around border
// The border width is in px and the rest is in rem, so split the computation of px and rem and add them at the end
$border-width-in-px: 2 * $border-width;
$header-links-height-without-border: (1rem * $line-height-base) + 4 * $nav-link-padding-y;
$header-links-height: calc(#{$border-width-in-px} + #{$header-links-height-without-border});

// Compute the height of the brand logo. Its height + top/bottom brand padding must be equal to the header links height.
$navbar-brand-padding: 2 * $navbar-brand-padding-y;
$menu-logo-height: calc(#{$header-links-height} - #{$navbar-brand-padding});

// Compute the full height of the header, including its padding
$navbar-padding: 2 * $navbar-padding-y;
$expanded-header-height: calc(#{$header-links-height} + #{$navbar-padding});

// When menu is collapsed, it is smaller than when the menu is expanded
$collapsed-padding: 2 * ($navbar-padding-y + $navbar-brand-padding-y);
$collapsed-header-height: calc(#{$menu-logo-height} + #{$collapsed-padding});
