@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";
@import "~bootstrap/scss/_grid";
@import "~bootstrap/scss/_modal";
@import "../../../scss/_index";

.CandidateApplicationPreview {
  .name-row {
    align-items: baseline;
  }

  .action-col {
    font-weight: bold;
  }

  .link {
    text-decoration: underline;
  }

  .Checkbox-col {
    @extend .col-auto;
    padding-right: 0;
  }

  .action-container {
    @extend .col-auto;
  }

  .new-badge {
    color: $maw-green !important;
    background-color: $maw-white !important;
    margin-left: 1em;
  }

  .new-badge-col {
    @extend .col-auto;
  }

  .checkbox:checked {
    background-color: $maw-green !important;
  }

  .statusCol {
    @extend .col-3, .col-xl-2;
    text-align: right;
  }

  .statusPill {
    padding: 0.2em 1em;
    border: solid 2px;
    border-radius: 1rem;
    border-color: $dark-background;
    margin-bottom: 0;
  }

  .noteParagraph {
    font-style: italic;
  }

  .noteParagraph, .effectiveCol {
    font-size: 0.8em;
  }

  .action-container, .statusCol {
    @extend .simple-spaced-rows;
  }

  .ApplicationUpdateModal {
    .modal-dialog {
      // Make this modal larger
      @extend .modal-lg;
    }
  }
}
