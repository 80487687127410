.ActionLoadingComponent {
  .overlapRegion {
    display: flex;

    .notLoadingContent {
      width: 100%;
    }

    .loadingContent {
      align-items: center;

      // To overlap previous component in overlapRegion
      width: 100%;
      margin-left: -100%;
    }

    .hidden {
      visibility: hidden;
    }

    .visible {
      visibility: inherit;
    }
  }
}
