@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_bootstrap-utilities-api";

.SearchBox {

  .input-group {
    // Align input group in the center of the page
    justify-content: center;
  }

  .search-input {
    max-width: 500px;
    // For column widths smaller than max-width, do not occupy 100% because search icon will wrap
    width: 80%;
  }

  .search-icon {
    // Make invisible on small screens
    @extend .d-none;
    @extend .d-md-block
  }
}
