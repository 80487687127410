// Colors
$maw-blue: #002942;
$maw-green: #68a62b;
$maw-grey: #F5F5F7;
$maw-white: white;
$light-background: #F2F2F2;
$dark-background: #d6d5db;
$footer-background: #2a2a2a;
$error-red: red;
$success-green: green;
$warning-orange: orange;
$label-grey: grey
