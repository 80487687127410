@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.JobDashboardApplicationsBlock {
  .actionLinkContainer {
    padding-top: 2em;
  }

  .jobCountCol {
    @extend .col-auto;
  }

  .jobCountRow {
    justify-content: center;
  }
  .ApplicationCountAction {
    width: 100%;
  }
}
