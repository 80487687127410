@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";
@import "../../../scss/_forms";

.ApplicationUpdateStatusConfirmHiringModal, .ApplicationUpdateStatusConfirmHiringCancelledModal {

  .reward-label {
    font-weight: bold;
  }

  .reward-details {
    text-align: left;
  }

  .effective-date {
    text-align: left;
  }

  div.effective-date-input-container {
    width: 10em;
    display: inline-block;
  }

  .effective-date-label + .effective-date-input-container {
    margin-left: 2em;
  }

  .effective-date-label {
    font-weight: bold;
  }
}

