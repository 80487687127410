@import "../../scss/_bootstrap-configuration";
@import "../../scss/_bootstrap-utilities-api";
@import "~bootstrap/scss/_grid";
@import "../../scss/_layout";
@import "../../scss/_index";
@import "../../scss/_colors";
@import "~bootstrap/scss/_modal";
@import "../../scss/_modals";

.SignUpForm {
  @extend .simple-spaced-rows;
}

.terms-modal {
  padding: $modal-padding;
  @extend .simple-spaced-rows;
  .action-close {
    text-align: center;
  }
  .close-icon {
    text-align: right;
    cursor: pointer;
  }
}

.termsModal {
  .modal-dialog {
    // Make this modal larger
    @extend .modal-xl;
  }
}
