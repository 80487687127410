@import "../../scss/bootstrap-configuration";
@import "../../scss/layout";

.HeaderNavbar {
  background-color: $maw-white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

  .header-toggle {
    font-size: 0.5em;
  }

  .header-collapse {
    justify-content: flex-end;
  }

  .navbar-nav {
    align-items: flex-end;
  }
}
