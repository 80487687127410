@import "../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.ResetPasswordPage {
  // Center items horizontally
  text-align: center;

  .row {
    justify-content: center;
  }

  .reset-password-block-col {
    @extend .col-md-9, .col-lg-6;
  }
}
