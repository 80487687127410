@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.JobDashboardCommissionBlock {
  .commission-col {
    font-size: 2em;
    margin-bottom: 1rem;
    text-align: center;
  }

  .col-label {
    font-size: 0.8em;
  }

  .commission-span {
    border-radius: 0.1em;
    background: $maw-green;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}
