@import "../../../scss/_colors";
@import "../../../scss/_layout";
@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.ChatGptBlock {

  .widget-block {
    margin: 0 !important;
  }

  .chat-col {
    @extend .simple-spaced-rows, .col-7;
  }

  .empty-col {
    @extend .col-1;
  }

  .protected-col {
    color: $success-green;
    font-size: 0.8rem;
  }

  .protected-badge {
    font-size: 1rem;
    margin-right: 0.25rem;
  }

  .prompt-title-col {
    h2 {
      font-size: 1rem;
    }
  }

  .prompt-list-col {
    &, .btn {
      font-size: 1rem;
    }
  }
}
