@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_colors";
@import "~bootstrap/scss/_grid";

.ChatMessage {
  .main-row {
    @extend .g-0;
  }

  .user-col {
    @extend .col-auto;
    width: 6rem;
  }

  .info-icon svg {
    font-size: 1rem;
    vertical-align: baseline;
    color: $label-grey;
  }

  .extra-info {
    border: 1px solid $label-grey;
    background-color: $light-background;
    padding: 0.25rem;
    font-size: 0.8rem;
  }
}
