@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_modal";
@import "../../../scss/_modals";

.ContentModal {

  .modal-dialog {
    // Center dialog vertically
    @extend .modal-dialog-centered;
  }

  .modal-body {
    padding: $modal-padding;

    h1 {
      font-size: 1.5rem;
    }
  }
}
