@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.JobSaveCommissionForm {
  .tip-col {
    @extend .col-md-4, .col-12;
  }

  .reward {
    font-weight: bold;
  }

  .over-commission-max {
    font-style: italic;
  }

  .currency-symbol {
    vertical-align: middle;
    font-size: 2 * $font-size-base;
  }

  .commission-col {
    font-size: 2 * $font-size-base;

    .currency {
      font-size: 1.5 * $font-size-base;
    }

    .ValidatedInput {
      display: inline-block;
      vertical-align: middle;
    }

    input {
      width: 7rem;
    }
  }

  .sidenote {
    font-style: italic;
  }

  .payment-terms-title {
    font-weight: bold;
  }
}
