@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_index";
@import "../../../scss/_layout";

.JobSaveDescriptionForm {
  @extend .spaced-children, .widget-block;
  .job-description-invite {
    text-align: center;
    font-size: 1.3em;
  }
  .job-description-generate {
    text-align: center;
  }
}

.JobSaveDescriptionWaitingModal, .JobSaveDescriptionGeneratingModal {
  text-align: center;

  .loading-icon {
    img {
      height: 3rem;
    }
  }
}
