@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.JobDashboardBlock {
  @extend .simple-spaced-rows;

  .blockPosition {
    @extend .col-12, .col-md-6, .col-lg-4;
  }

  .blockContainerApplication {
    @extend .blockPosition;
  }

  .blockContainerCommission {
    @extend .blockPosition;
  }

  .blockContainerStatistics {
    @extend .col-12, .col-lg-4;
  }

  .widget-block {
    height: 20em;
  }
  .blockContainer {
    @extend .wrap-spaced-children;
  }
}
