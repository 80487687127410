@import "../../../scss/_colors";

.RecruiterFeedbackUpdateForm {
  .rating-row {
    justify-content: center;
  }
  .label-row {
    color: $label-grey;
    padding-top: 1em;
  }
}
