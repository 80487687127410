@import "./_colors";

.form-with-rows {

  @extend .spaced-children;

  .form-label {
    // Override Bootstrap
    margin-bottom: 0;
  }

  .form-required {
    &:after {
      content: " *"
    }
  }

  .form-info {
    font-size: small;
  }

  .form-actions {
    margin-top: 1rem;
    text-align: center;

    > * + * {
      // Space between buttons in a row
      margin-left: 1rem;
    }
  }

  .form-error {
    color: $error-red;
  }
}
