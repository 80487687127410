@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.ApplicationPreview {
  .name-row {
    align-items: center;
  }

  // Space between rows
  .name-row + .note-row {
    margin-top: 2rem;
  }

  .candidate-name {
    font-weight: bold;
    text-decoration: underline;
  }

  .candidate-name-col {
    @extend .col-12, .col-sm-4;
  }

  .referrer-col {
    @extend .col-12, .col-sm-6;
  }

  .ref-name {
    text-decoration: underline;
  }

  .action-icon {
    @extend .col-2, .col-sm-1;
  }
}
