@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.JobSearchItem {
  margin-bottom: 1rem;

  .name-col {
    text-align: left;
    font-weight: bold;
    font-size: $font-size-base * 1.25;
  }

  .commission-col {
    font-weight: bold;
    color: $maw-green;
    font-size: $font-size-base * 2;
  }

  .commission-title-col {
    font-weight: bold;
  }
  .last-col {
    @extend .col-auto;
    text-align: center;
  }
  .logo-col {
    @extend .col-auto;
  }
  .job-information-col {
    @extend .spaced-children;
  }
}
