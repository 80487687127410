@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_index";
@import "../../../scss/_colors";

.JobSearchResult {
  width: 100%;
  .job-title {
    font-weight: bold;
    text-align: left;
  }
  .job-location {
    text-align: left;
  }
  .job-commission {
    @extend .col-12, .col-lg-2, .text-lg-end;
    text-align: left;
  }
  .date-col {
    @extend .col-12, .col-lg-2, .text-lg-center;
    text-align: left;
  }
  .job-count {
    @extend .col-12, .col-lg-3, .text-lg-end;
    text-align: left;
  }
  .badge-col {
    @extend .text-end, .text-sm-end;
  }
  .draft-badge {
    background-color: $label-grey;
    border-radius: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  &.btn {
    border: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
