@import "../../../scss/_layout";

.JobSavePublicationForm {
  @extend .double-spaced-rows;
  .title-row {
    text-align: center;
    font-size: 2em;
  }

  .message-col-block{
    @extend .simple-spaced-rows;
  }

  .title {
    font-weight: bold;
  }
}
